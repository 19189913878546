import React from 'react';
import { useParams, Redirect, useHistory } from 'react-router-dom';
import { useSelector } from 'react-redux';
import InstructionsPage from './components/InstructionsPage';
import { GAME_PATH } from '../index';
import GlobalState from '../store/GlobalState';

export type Page = {
  title: string;
  previousPage: string;
  nextPage: string;
};

export const PAGES: Page[] = [
  {
    title: 'Home',
    previousPage: '',
    nextPage: '/instructions/2',
  },
  {
    title: 'First Instructions Page',
    previousPage: '',
    nextPage: '/instructions/2',
  },
  {
    title: 'Second Instructions Page',
    previousPage: '/instructions/1',
    nextPage: '/instructions/3',
  },
  {
    title: 'Third Instructions Page',
    previousPage: '/instructions/2',
    nextPage: '/instructions/4',
  },
  {
    title: 'Fourth Instructions Page',
    previousPage: '/instructions/3',
    nextPage: '/instructions/5',
  },
  {
    title: 'Fifth Instructions Page',
    previousPage: '/instructions/1',
    nextPage: '/play',
  },
];

export const INSTRUCTIONS_PATH = '/instructions/:id';
export const FIRST_INSTRUCTIONS_PATH = '/instructions/1';

const Instructions = () => {
  const { id } = useParams();
  const validPages = Array.from(Array(PAGES.length), (_, i) => i + 1);

  const parsedId = parseInt(id, 10);

  const history = useHistory();
  const playerInfo = useSelector((state: GlobalState) => state.playerInfo);

  if (!playerInfo) history.push(GAME_PATH);

  if (!(parsedId in validPages)) {
    return <Redirect to={GAME_PATH} />;
  }

  const currentPage = PAGES[parsedId];

  return (
    <InstructionsPage
      pageNumber={id}
      previousPage={currentPage.previousPage}
      nextPage={currentPage.nextPage}
      lastPage={Number(id) === PAGES.length - 1 ? '1' : '0'}
    />
  );
};

export default Instructions;
