import React from 'react';
import { Droppable, Draggable } from 'react-beautiful-dnd';

import { SideOrAssigned } from '../../../store/slices/gameSlice';
import { Character } from '../../types';

import CharacterTitle, { CharacterTitleContainer } from '../CharacterTitle';
import CharacterDescription from '../CharacterDescription';

import classNames from './SideCharacter.module.css';

export type SideCharacterProps = {
  character: Character | null;
  slotIndex: number;
  onMouseEnter: () => void;
  onMouseLeave: () => void;
  descriptionOpen: boolean;
};

export const SideCharacter = ({
  character,
  slotIndex,
  onMouseEnter,
  onMouseLeave,
  descriptionOpen,
}: SideCharacterProps) => {
  let innerNode: React.ReactNode = (
    <CharacterTitleContainer visibleBackground />
  );

  if (character)
    innerNode = (
      <Draggable draggableId={character.imageUrl} index={0}>
        {(provided, snapshot) => (
          <>
            <CharacterTitleContainer visibleBackground>
              <CharacterTitle
                className={classNames.title}
                ref={provided.innerRef}
                {...provided.draggableProps}
                {...provided.dragHandleProps}
                data-testid={`draggable-${character.imageUrl}`}
              >
                {character.title}
              </CharacterTitle>
            </CharacterTitleContainer>
            <CharacterDescription
              open={descriptionOpen && !snapshot.isDragging}
            >
              {character.description}
            </CharacterDescription>
          </>
        )}
      </Draggable>
    );

  return (
    <Droppable
      droppableId={`${SideOrAssigned.SIDE}${slotIndex}`}
      isDropDisabled={!!character}
    >
      {(provided) => (
        <div
          className={classNames.container}
          ref={provided.innerRef}
          {...provided.droppableProps}
          onMouseEnter={onMouseEnter}
          onMouseLeave={onMouseLeave}
        >
          {innerNode}
        </div>
      )}
    </Droppable>
  );
};

export default SideCharacter;
