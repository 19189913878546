import React from 'react';
import { Link } from 'react-router-dom';
import classes from './PDFCertificateCreator.module.css';
import { MAIN_PATH } from '../../Main';

type CertificateProps = {
  name: string;
  school: string;
  tries: number;
  triesString: string;
  saveDoc: Function;
  printDoc: Function;

};

const PDFCertificateCreator = ({
  name,
  school,
  tries,
  triesString,
  saveDoc,
  printDoc,
}: CertificateProps) => {
  const handleSave = () => saveDoc();
  const handlePrint = () => printDoc();

  return (
    <div>
      <div className={classes.certificate}>
        <h2>Congratulations</h2>
        <h1 data-testid="their-name">{name}</h1>
        <h3>
          <i data-testid="their-school">{school}</i>
        </h3>
        <h3>You figured out who&apos;s who in Ancient Egypt!</h3>
        <h3>
          In only <span data-testid="num-tries">{tries}</span>{' '}
          <span data-testid="str-tries">{ triesString }</span>!
        </h3>
      </div>
      <div className={classes.Buttons}>
        <button
          type="button"
          data-testid="save-btn"
          className={classes.saveButton}
          onClick={handleSave}
        />
        <button
          type="button"
          data-testid="print-btn"
          className={classes.printButton}
          onClick={handlePrint}
        />
        <Link data-testid="play-btn" to={MAIN_PATH}>
          <button type="button" className={classes.playButton} />
        </Link>
      </div>
    </div>
  );
};

export default PDFCertificateCreator;
