import React, { PropsWithChildren } from 'react';
import cx from 'classnames';

import papyrusImage from '../../assets/papyrus-thin.png';

import classNames from './CharacterTitle.module.css';

export type CharacterTitleContainerProps = {
  visibleBackground?: boolean;
};

export const CharacterTitleContainer = ({
  children,
  visibleBackground,
}: PropsWithChildren<CharacterTitleContainerProps>) => (
  <div
    className={cx(classNames.titleContainer, {
      [classNames.visibleBackground]: visibleBackground,
    })}
  >
    <div className={classNames.placeholderBackground} />
    {children}
  </div>
);

interface CharacterTitleProps extends React.HTMLProps<HTMLHeadingElement> {
  children: string;
  isDragging?: boolean;
  placeholderTitle?: boolean;
  descriptionOpen?: boolean;
}

type Ref = HTMLDivElement;

export const CharacterTitle = React.forwardRef<Ref, CharacterTitleProps>(
  ({ children, placeholderTitle, descriptionOpen, ...rest }, ref) => (
    <>
      {descriptionOpen && <span className={classNames.toggleButton}>a</span>}
      <h3
        {...rest}
        ref={ref}
        className={cx(classNames.title, {
          [classNames.placeholder]: placeholderTitle,
        })}
      >
        <div
          className={classNames.background}
          style={{
            backgroundImage: !placeholderTitle
              ? `url(${papyrusImage})`
              : 'none',
          }}
        >
          {children}
        </div>
      </h3>
    </>
  )
);

export default CharacterTitle;
