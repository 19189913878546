import React from 'react';
import { useHistory } from 'react-router-dom';
import classNames from './ContinueButton.module.css';
import { GAME_PATH } from '../../../Game';

export type ContinueButtonProps = {
  nextPage: string;
  lastPage: string;
};

const ContinueButton = (props: ContinueButtonProps) => {
  const history = useHistory();

  function handleClick() {
    history.push(GAME_PATH + props.nextPage);
  }

  const { lastPage } = props;

  return (
    <figure>
      <img
        src={
          lastPage === '0'
            ? '/img/instructions/nextPageCat.png'
            : '/img/instructions/begin-game.png'
        }
        className={
          lastPage === '0'
            ? classNames.continueButton
            : classNames.beginGameButton
        }
        alt="Continue"
        onClick={handleClick}
      />
      <figcaption> {lastPage === '0' ? 'Next' : ''} </figcaption>
    </figure>
  );
};

export default ContinueButton;
