import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import PlayerType from '../../WelcomeForm/PlayerType';

/**
 * This defines the state within the slice
 */
export type PlayerInfo = {
  name: string;
  schoolName: string;
  playerType: PlayerType;
} | null;

export const {
  actions: { setPlayerInfo },
  reducer,
} = createSlice({
  name: 'playerInfo',
  initialState: null as PlayerInfo,
  reducers: {
    setPlayerInfo: (state: PlayerInfo, action: PayloadAction<PlayerInfo>) =>
      action.payload,
  },
});
