import React, { useState, useRef, useEffect } from 'react';
import cx from 'classnames';

import classNames from './CharacterDescription.module.css';

export type CharacterDescriptionProps = {
  open: boolean;
  children: string;
};

export const CharacterDescription = ({
  open,
  children,
}: CharacterDescriptionProps) => {
  const contentRef = useRef<HTMLDivElement>(null);

  const [heightState, setHeight] = useState('0px');

  useEffect(() => {
    if (!contentRef.current) return;

    const height = `${contentRef.current.clientHeight}px`;

    if (height !== heightState) setHeight(height);
  });

  return (
    <div
      className={cx(classNames.wrapper, { [classNames.open]: open })}
      style={{ height: open ? heightState : '0px' }}
    >
      <div ref={contentRef} className={classNames.content}>
        <ul>
          {children.split('\n').map((i) => (
            <li key={i}>{i}</li>
          ))}
        </ul>
      </div>
    </div>
  );
};

export default CharacterDescription;
