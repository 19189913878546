import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import _shuffle from 'lodash/shuffle';
import _isEqual from 'lodash/isEqual';

import CHARACTERS from '../../PlayGame/assets/characters';
import { Character } from '../../PlayGame/types';

export type GameSlice = {
  sideSlots: (string | null)[];
  assignedCharacters: {
    expected: string;
    assigned: string | null;
  }[];
  tries: number;
};

export const generateInitialState: () => GameSlice = () => {
  const sideSlots = _shuffle<Character>(CHARACTERS).map(
    (character) => character.imageUrl
  );

  const assignedCharacters = _shuffle<Character>(
    CHARACTERS
  ).map((character) => ({ expected: character.imageUrl, assigned: null }));

  return { sideSlots, assignedCharacters, tries: 1 };
};

export enum SideOrAssigned {
  SIDE = 'sideSlots',
  ASSIGNED = 'assignedCharacters',
}

export type DragTarget = {
  sideOrAssigned: SideOrAssigned;
  index: number;
};

export const {
  reducer,
  actions: { resetGame, moveCharacter, incrementTries, solveGame },
} = createSlice({
  name: 'game',
  initialState: generateInitialState(),
  reducers: {
    resetGame: generateInitialState,
    moveCharacter: (
      { assignedCharacters, sideSlots },
      action: PayloadAction<{
        destination: DragTarget;
        source: DragTarget;
      }>
    ) => {
      const { destination, source } = action.payload;

      if (_isEqual(destination, source)) return; // no changes needed if the character is dropped in the same place

      let characterId: string;

      // Remove the character from the source
      if (source.sideOrAssigned === SideOrAssigned.ASSIGNED) {
        characterId = assignedCharacters[source.index].assigned as string;
        assignedCharacters[source.index].assigned = null;
      } else {
        characterId = sideSlots[source.index] as string;

        sideSlots[source.index] = null;
      }

      // Add the character to the destination
      if (destination.sideOrAssigned === SideOrAssigned.ASSIGNED) {
        assignedCharacters[destination.index].assigned = characterId;
      } else {
        sideSlots[destination.index] = characterId;
      }
    },
    incrementTries: (state) => {
      state.tries += 1;
    },
    solveGame: (state) => {
      return {
        sideSlots: Array(state.sideSlots.length).fill(null),
        assignedCharacters: state.assignedCharacters.map(({ expected }) => ({
          expected,
          assigned: expected,
        })),
        tries: state.tries,
      };
    },
  },
});
