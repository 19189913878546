import React from 'react';
import { Route } from 'react-router-dom';

import classes from './Game.module.css';

import PDFCertificateCreatorContainer, {
  CERTIFICATE_PATH,
} from './CertificateCreator';
import WelcomeForm from './WelcomeForm';
import PlayGame, { PLAY_GAME_PATH } from './PlayGame';
import Instructions, { INSTRUCTIONS_PATH } from './Instructions';

import { GAME_PATH } from './gamePath';

export { GAME_PATH };

const Game: React.FC = () => {
  return (
    <div className={classes.Background}>
      <Route exact path={GAME_PATH}>
        <WelcomeForm />
      </Route>
      <Route exact path={GAME_PATH + INSTRUCTIONS_PATH}>
        <Instructions />
      </Route>
      <Route path={GAME_PATH + PLAY_GAME_PATH}>
        <PlayGame />
      </Route>
      <Route path={GAME_PATH + CERTIFICATE_PATH}>
        <PDFCertificateCreatorContainer />
      </Route>
    </div>
  );
};

export default Game;
