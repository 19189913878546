import React, { useState, useRef, useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { DragDropContext, DropResult } from 'react-beautiful-dnd';
import { useHistory } from 'react-router-dom';

import GlobalState from '../store/GlobalState';
import { CERTIFICATE_PATH } from '../CertificateCreator';
import { GAME_PATH } from '..';
import { FIRST_INSTRUCTIONS_PATH } from '../Instructions';

import CHARACTERS from './assets/characters';
import checkAnswersImage from './assets/check-answers.png';
import instructionsImage from './assets/instructions.png';

import {
  SideOrAssigned,
  moveCharacter,
  incrementTries,
  solveGame,
  DragTarget,
} from '../store/slices/gameSlice';
import { Character } from './types';
import SideCharacter from './components/SideCharacter';
import FailureModal from './components/FailureModal';
import CharacterSlot from './components/CharacterSlot';

import classNames from './PlayGame.module.css';

export const PLAY_GAME_PATH = '/play';

const CHARACTERS_BY_ID = CHARACTERS.reduce<{ [key: string]: Character }>(
  (previous, character) => {
    previous[character.imageUrl] = character;

    return previous;
  },
  {}
);

export const PlayGame = () => {
  const missingPlayerInfo = useSelector(
    ({ playerInfo }: GlobalState) => !playerInfo
  );

  const history = useHistory();

  if (missingPlayerInfo) history.replace(GAME_PATH);

  const { assignedCharacters, firstColumn, secondColumn } = useSelector(
    ({ game: { sideSlots, assignedCharacters } }: GlobalState) => {
      const halfNSlots = Math.floor(sideSlots.length / 2);

      return {
        firstColumn: sideSlots.slice(0, halfNSlots),
        secondColumn: sideSlots.slice(halfNSlots),
        assignedCharacters,
      };
    }
  );

  const { allAssignmentsCorrect } = useSelector(
    ({ game: { assignedCharacters } }: GlobalState) => {
      const nCorrectAssignments = assignedCharacters.reduce(
        (previous, assignment) => {
          if (assignment.assigned === assignment.expected) return previous + 1;

          return previous;
        },
        0
      );

      return {
        nCorrectAssignments,
        allAssignmentsCorrect:
          nCorrectAssignments === assignedCharacters.length,
      };
    }
  );

  const dispatch = useDispatch();

  const [failureModalVisible, setFailureModalVisible] = useState(false);

  const onCheckAnswers = () => {
    if (allAssignmentsCorrect) {
      return history.push(GAME_PATH + CERTIFICATE_PATH);
    }

    dispatch(incrementTries());

    setFailureModalVisible(true);
  };

  const onFailureModalContinueGame = () => {
    setFailureModalVisible(false);
  };

  const onRepeatInstructions = () => {
    history.push(GAME_PATH + FIRST_INSTRUCTIONS_PATH);
  };

  const [isDragging, setIsDragging] = useState(false);

  const onDragEnd = (dropResult: DropResult) => {
    setIsDragging(false);

    // don't do anything if item is not inside any droppable region
    if (!dropResult.destination) return;

    let sideOrAssigned = dropResult.source.droppableId.includes(
      SideOrAssigned.ASSIGNED
    )
      ? SideOrAssigned.ASSIGNED
      : SideOrAssigned.SIDE;

    const source: DragTarget = {
      sideOrAssigned,
      index: parseInt(
        dropResult.source.droppableId.replace(sideOrAssigned, ''),
        10
      ),
    };

    sideOrAssigned = dropResult.destination.droppableId.includes(
      SideOrAssigned.ASSIGNED
    )
      ? SideOrAssigned.ASSIGNED
      : SideOrAssigned.SIDE;

    const destination: DragTarget = {
      sideOrAssigned,
      index: parseInt(
        dropResult.destination.droppableId.replace(sideOrAssigned, ''),
        10
      ),
    };

    dispatch(moveCharacter({ destination, source }));
  };

  const [openedDescriptionIdx, setOpenedDescriptionIdx] = useState(-1);

  const mouseLeaveTimeoutRef = useRef<ReturnType<typeof setTimeout> | null>(
    null
  );

  const clearMouseLeaveTimeout = () => {
    if (mouseLeaveTimeoutRef.current !== null)
      clearTimeout(mouseLeaveTimeoutRef.current);
  };

  const onMouseLeave = () => {
    if (!isDragging) {
      clearMouseLeaveTimeout();

      mouseLeaveTimeoutRef.current = setTimeout(() => {
        setOpenedDescriptionIdx(-1);
      }, 1000);
    }

    // eslint-disable-next-line no-console
    if (isDragging) console.log('wow');
  };

  const onMouseEnter = (idx: number) => {
    if (!isDragging) {
      clearMouseLeaveTimeout();

      mouseLeaveTimeoutRef.current = setTimeout(() => {
        setOpenedDescriptionIdx(idx);
      }, 100);
    }
  };

  useEffect(() => () => clearMouseLeaveTimeout(), []);

  useEffect(() => {
    const onKeyPress = (e: KeyboardEvent) => {
      // 's' key
      if (e.keyCode === 83 && process.env.NODE_ENV === 'development')
        dispatch(solveGame());
    };

    document.addEventListener('keydown', onKeyPress);

    return () => document.removeEventListener('keydown', onKeyPress);
  }, []);

  return (
    <DragDropContext
      onDragEnd={onDragEnd}
      onDragStart={() => setIsDragging(true)}
    >
      <div className={classNames.container}>
        {failureModalVisible && (
          <FailureModal
            onFailureModalContinueGame={onFailureModalContinueGame}
          />
        )}
        <div className={classNames.column}>
          <button
            type="button"
            className={classNames.repeatInstructionsButton}
            style={{ backgroundImage: `url(${instructionsImage})` }}
            onClick={onRepeatInstructions}
            data-testid="repeat-instructions"
          />
          {firstColumn.map((characterId, idx) => {
            const character = characterId
              ? CHARACTERS_BY_ID[characterId]
              : null;

            return (
              <SideCharacter
                // eslint-disable-next-line react/no-array-index-key
                key={idx}
                character={character}
                slotIndex={idx}
                descriptionOpen={openedDescriptionIdx === idx}
                onMouseEnter={() => character && onMouseEnter(idx)}
                onMouseLeave={() => character && onMouseLeave()}
              />
            );
          })}
        </div>
        <div
          className={classNames.charactersColumn}
          style={{
            flex: firstColumn.length,
            gridTemplateColumns: `repeat(${firstColumn.length}, ${
              100 / firstColumn.length
            }% )`,
            gridTemplateRows: 'repeat(2, 50%)',
          }}
        >
          {assignedCharacters.map(({ assigned, expected }, idx) => {
            const assignedCharacter = assigned
              ? CHARACTERS_BY_ID[assigned]
              : undefined;

            return (
              <CharacterSlot
                imageUrl={expected}
                slotIndex={idx}
                // eslint-disable-next-line react/no-array-index-key
                key={idx}
                assignedCharacter={assignedCharacter}
              />
            );
          })}
        </div>
        <div className={classNames.column}>
          <button
            type="button"
            onClick={onCheckAnswers}
            className={classNames.checkButton}
            style={{ backgroundImage: `url(${checkAnswersImage})` }}
            data-testid="check-answers"
          />
          {secondColumn.map((characterId, idx) => {
            const character = characterId
              ? CHARACTERS_BY_ID[characterId]
              : null;

            const slotIndex = firstColumn.length + idx;

            return (
              <SideCharacter
                // eslint-disable-next-line react/no-array-index-key
                key={idx}
                character={character}
                slotIndex={slotIndex}
                descriptionOpen={openedDescriptionIdx === slotIndex}
                onMouseEnter={() => character && onMouseEnter(slotIndex)}
                onMouseLeave={() => character && onMouseLeave()}
              />
            );
          })}
        </div>
      </div>
    </DragDropContext>
  );
};

export default PlayGame;
