import React from 'react';
import { Draggable, Droppable } from 'react-beautiful-dnd';
import cx from 'classnames';

import classNames from './CharacterSlot.module.css';
import { Character } from '../../types';
import CharacterTitle, { CharacterTitleContainer } from '../CharacterTitle';

import { SideOrAssigned } from '../../../store/slices/gameSlice';

const EMPTY_DESCRIPTION_MESSAGE = 'Drag the character here';
const EMPTY_TITLE_MESSAGE = 'Who am I?';

export type CharacterSlotProps = {
  imageUrl: string;
  assignedCharacter?: Character;
  slotIndex: number;
};

export const CharacterSlot = ({
  assignedCharacter,
  slotIndex,
  imageUrl,
}: CharacterSlotProps) => (
  <Droppable
    droppableId={`${SideOrAssigned.ASSIGNED}${slotIndex}`}
    isDropDisabled={!!assignedCharacter}
  >
    {(provided, snapshot) => (
      <div
        className={classNames.characterSlot}
        ref={provided.innerRef}
        {...provided.droppableProps}
        data-testid={`slot-${imageUrl}`}
      >
        <CharacterTitleContainer>
          {assignedCharacter && (
            <Draggable draggableId={assignedCharacter.imageUrl} index={0}>
              {(provided) => (
                <CharacterTitle
                  className={classNames.characterTitle}
                  ref={provided.innerRef}
                  {...provided.draggableProps}
                  {...provided.dragHandleProps}
                  data-testid={`draggable-${imageUrl}`}
                >
                  {assignedCharacter.title}
                </CharacterTitle>
              )}
            </Draggable>
          )}
          <CharacterTitle
            className={classNames.characterTitle}
            placeholderTitle
          >
            {EMPTY_TITLE_MESSAGE}
          </CharacterTitle>
        </CharacterTitleContainer>
        <div className={classNames.bottom}>
          <div
            className={cx(classNames.image, {
              [classNames.assigned]: !!assignedCharacter,
              [classNames.draggingOver]: snapshot.isDraggingOver,
            })}
            style={{
              transform: snapshot.isDraggingOver
                ? `scale(1.1) rotate(${(Math.random() - 0.5) * 4}deg)` // rotate a random ammount between -2 and 2 deg
                : 'none',
              backgroundImage: `url(${imageUrl})`,
            }}
          />
          <div className={cx(classNames.description, classNames.assigned)}>
            {assignedCharacter && (
              <ul>
                {assignedCharacter?.description.split('\n').map((i) => (
                  <li key={i}>{i}</li>
                ))}
              </ul>
            )}
          </div>

          <div className={cx(classNames.description)}>
            <ul>
              <li>{EMPTY_DESCRIPTION_MESSAGE}</li>
            </ul>
          </div>
        </div>
      </div>
    )}
  </Droppable>
);

export default CharacterSlot;
