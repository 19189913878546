import React from 'react';
import { Formik, Field, Form, ErrorMessage } from 'formik';
import * as Yup from 'yup';
import { useDispatch } from 'react-redux';
import { useHistory } from 'react-router-dom';
import classes from './WelcomeForm.module.css';
import PlayerType from './PlayerType';

import { resetGame } from '../store/slices/gameSlice';
import { PlayerInfo, setPlayerInfo } from '../store/slices/playerInfoSlice';
import { GAME_PATH } from '../gamePath';

export const NAME_FIELD = 'name';
export const SCHOOL_NAME_FIELD = 'schoolName';
export const PLAYER_TYPE_FIELD = 'playerType';
export const NAME_REQUIRED_MESSAGE = 'Please enter your name';
export const SCHOOL_NAME_REQUIRED_MESSAGE = 'What school are you from?';
export const NAME_INCORRECT_MESSAGE = 'Must be a real name';
export const PLAYER_TYPE_INCORRECT_MESSAGE = 'Are you a student or a teacher?';
export const SCHOOL_NAME_INCORRECT_MESSAGE = '';

const WelcomeForm: React.FC<{}> = () => {
  const dispatch = useDispatch();
  const history = useHistory();

  const startInstructions = (
    playerInfo: PlayerInfo,
    resetForm: Function
  ): void => {
    dispatch(setPlayerInfo(playerInfo));
    dispatch(resetGame());

    resetForm();
    history.push(`${GAME_PATH}/instructions/1`);
  };

  const playerInfo: PlayerInfo = {
    name: '',
    schoolName: '',
    playerType: PlayerType.STUDENT,
  };

  return (
    <Formik
      initialValues={playerInfo}
      validationSchema={Yup.object({
        name: Yup.string()
          .min(2, NAME_INCORRECT_MESSAGE)
          .max(128, 'Must be 128 characters or less')
          .required(NAME_REQUIRED_MESSAGE),
        schoolName: Yup.string()
          .max(128, 'Must be 128 characters or less')
          .required(SCHOOL_NAME_REQUIRED_MESSAGE),
        playerType: Yup.string()
          .oneOf([PlayerType.STUDENT, PlayerType.TEACHER])
          .required(PLAYER_TYPE_INCORRECT_MESSAGE),
      })}
      onSubmit={(values, { resetForm }) => {
        startInstructions(values, resetForm);
      }}
    >
      <div className={classes.WelcomeForm}>
        <Form>
          <h1>Get ready for your ancient egyptian adventure!</h1>

          <div className={classes.InputBox}>
            <label htmlFor={NAME_FIELD}>Your name</label>
            <Field name={NAME_FIELD} type="text" />
            <span data-testid="errors-name" className={classes.Error}>
              <ErrorMessage name={NAME_FIELD} />
            </span>
          </div>

          <div className={classes.InputBox}>
            <label htmlFor={SCHOOL_NAME_FIELD}>Your school name</label>
            <Field name={SCHOOL_NAME_FIELD} type="text" />
            <span data-testid="errors-schoolName" className={classes.Error}>
              <ErrorMessage name={SCHOOL_NAME_FIELD} />
            </span>
          </div>

          <div className={classes.PlayerTypeBox}>
            <label htmlFor={PLAYER_TYPE_FIELD}>
              Are you a student or a teacher?
            </label>

            <Field component="div" name={PLAYER_TYPE_FIELD}>
              <div className={classes.PlayerType}>
                <input
                  type="radio"
                  name={PLAYER_TYPE_FIELD}
                  value={PlayerType.STUDENT}
                  defaultChecked
                />
                <label>Student</label>
              </div>

              <div className={classes.PlayerType}>
                <input
                  type="radio"
                  name={PLAYER_TYPE_FIELD}
                  value={PlayerType.TEACHER}
                />
                <label>Teacher</label>
              </div>
            </Field>

            <span data-testid="errors-playerType" className={classes.Error}>
              <ErrorMessage name="playerType" />
            </span>
          </div>

          <button type="submit" className={classes.Button}>
            Let&apos;s go!
          </button>
        </Form>
      </div>
    </Formik>
  );
};

export default WelcomeForm;
