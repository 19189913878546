import { configureStore, Middleware, DeepPartial } from '@reduxjs/toolkit';
import createLogger from 'redux-logger';

import GlobalState from './GlobalState';

import reducer from './slices';

export default function createStore(preloadedState?: DeepPartial<GlobalState>) {
  const middleware: Middleware[] = [];

  if (process.env.NODE_ENV === 'development') {
    middleware.push(createLogger);
  }

  return configureStore({ reducer, middleware, preloadedState });
}
