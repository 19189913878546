import React from 'react';
import { useHistory } from 'react-router-dom';
import classNames from './PreviousPageButton.module.css';
import { GAME_PATH } from '../../../Game';

export type PreviousPageButtonProps = {
  previousPage: string;
  lastPage: string;
};

const PreviousPageButton = (props: PreviousPageButtonProps) => {
  const history = useHistory();

  function handleClick() {
    history.push(GAME_PATH + props.previousPage);
  }

  const { lastPage } = props;

  return (
    <figure>
      <img
        src={
          lastPage === '0'
            ? '/img/instructions/prevPageCat.png'
            : '/img/instructions/repeat-instructions.png'
        }
        className={
          lastPage === '0'
            ? classNames.previousButton
            : classNames.repeatInstructionsButton
        }
        alt="back"
        onClick={handleClick}
      />
      <figcaption> {lastPage === '0' ? 'Back' : ''} </figcaption>
    </figure>
  );
};

export default PreviousPageButton;
