import React from 'react';
import { Route } from 'react-router-dom';
import LandingPage from './components/LandingPage';
import Layout from './components/Layout';

export const MAIN_PATH = '/';

const Main = () => {
  return (
    <Layout>
      <Route path={MAIN_PATH} exact>
        <LandingPage />
      </Route>
    </Layout>
  );
};

export default Main;
