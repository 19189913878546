import React from 'react';
import ContinueButton from '../ContinueButton';
import PreviousPageButton from '../PreviousPageButton';
import classNames from './InstructionsPage.module.css';

const FIRST_PAGE_TEXT = (
  <h1>
    Imagine you are walking around an Ancient Egyptian town.
    <br />
    <br />
    How would you distinguish between a pharaoh and a child?
    <br />
    <br />
    A priest and a soldier?
    <br />
    <br />
    How would YOU find out who is who?
  </h1>
);

const SECOND_PAGE_TEXT = (
  <>
    <h1>
      It&apos;s easy!
      <br />
      <br />
      Take a look at the Ancient Egyptian people drawn on the cards.
      <br />
    </h1>
    <img
      src="/img/instructions/allCharacters.png"
      alt="AllCharacters"
      className={classNames.AllCharacters}
    />
  </>
);

const THIRD_PAGE_TEXT = (
  <>
    <img
      src="/img/instructions/allCharacters.png"
      alt="AllCharacters"
      className={classNames.AllCharacters}
    />
    <h1>
      What are they wearing?
      <br />
      What are they holding?
      <br />
      What might their jobs have been?
      <br />
    </h1>
  </>
);
const FOURTH_PAGE_TEXT = (
  <h1>
    Drag the characters name onto the matching picture.
    <br />
    Use their description to help you.
    <br />
    <img
      className={classNames.gifClass}
      src="/img/instructions/instructions_video.gif"
      alt="Instructions"
      width="40%"
    />
  </h1>
);

const FIFTH_PAGE_TEXT = (
  <h1 className={classNames.StartHeading}>
    <br />
    <br />
    <br />
    Are you ready to start the game?
  </h1>
);
export const PAGE_TEXT = [
  FIRST_PAGE_TEXT,
  SECOND_PAGE_TEXT,
  THIRD_PAGE_TEXT,
  FOURTH_PAGE_TEXT,
  FIFTH_PAGE_TEXT,
];

export const TEXT_ID = 'Text';

export type InstructionsPageProps = {
  nextPage: string;
  previousPage: string;
  pageNumber: number;
  lastPage: string;
};

const InstructionsPage = (props: InstructionsPageProps) => {
  const { pageNumber, nextPage, previousPage, lastPage } = props;
  const text = PAGE_TEXT[pageNumber - 1];

  return (
    <div className={classNames.InstructionsPage}>
      <div data-testid={TEXT_ID}>{text}</div>
      <div
        className={
          lastPage === '0'
            ? classNames.leftButton
            : classNames.repeatInstructionsButton
        }
      >
        <PreviousPageButton previousPage={previousPage} lastPage={lastPage} />
      </div>
      <div
        className={
          lastPage === '0' ? classNames.rightButton : classNames.beginGameButton
        }
      >
        <ContinueButton nextPage={nextPage} lastPage={lastPage} />
      </div>
    </div>
  );
};

export default InstructionsPage;
