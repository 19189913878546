import React from 'react';
import { useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';
import PDFCertificateCreator from './PDFCertificateCreator';
import GlobalState from '../store/GlobalState';
import createPDF from '../../../utils/createPDF';
import { MAIN_PATH } from '../../Main';

export const CERTIFICATE_PATH = '/complete';

const PDFCertificateCreatorContainer = () => {
  const history = useHistory();
  const playerInfo = useSelector((state: GlobalState) => state.playerInfo);
  const gameInfo = useSelector((state: GlobalState) => state.game);
  if (playerInfo === null) {
    history.push(MAIN_PATH);
    return null;
  }
  const { name, schoolName } = playerInfo;
  const {tries} = gameInfo;

  const handleSave = () => {
    const triesString = (tries === 1 ? 'try' : 'tries');
    const doc = createPDF({ name, schoolName, tries, triesString });

    doc.save('you-figured-out-whos-who.pdf');
  };

  const handlePrint = () => {
    const triesString = (tries === 1 ? 'try' : 'tries');
    const doc = createPDF({ name, schoolName, tries, triesString });

    doc.autoPrint();
    window.open(doc.output('bloburl'), '_blank');
  };

  return (
    <PDFCertificateCreator
      name={name}
      school={schoolName}
      tries={tries}
      triesString={(tries === 1 ? 'try' : 'tries')}
      saveDoc={handleSave}
      printDoc={handlePrint}
    />
  );
};

export default PDFCertificateCreatorContainer;
