import React from 'react';
import { Link } from 'react-router-dom';
import { GAME_PATH } from '../../../Game';
import classes from './LandingPage.module.css';

export const LANDING_PAGE_PATH = '/';
export const HERO_BOX_HEADING = 'Welcome to the museum!';
export const PLAY_BUTTON_TEXT = 'Play!';

const LandingPage: React.FC = () => {
  return (
    <div>
      <div className={classes.HeroBox}>
        <div className={classes.Fade} />
        <div className={classes.Action}>
          <h1>{HERO_BOX_HEADING}</h1>
          <Link to={GAME_PATH}>{PLAY_BUTTON_TEXT}</Link>
        </div>
      </div>
    </div>
  );
};

export default LandingPage;
