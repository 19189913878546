import React from 'react';
import { Provider } from 'react-redux';
import { BrowserRouter as Router, Switch, Route } from 'react-router-dom';

import Main, { MAIN_PATH } from './routes/Main';
import createStore from './routes/Game/store';

import Game, { GAME_PATH } from './routes/Game';

const store = createStore();

function App() {
  return (
    <Provider store={store}>
      <Router>
        <Switch>
          <Route path={GAME_PATH}>
            <Game />
          </Route>
          <Route path={MAIN_PATH}>
            <Main />
          </Route>
        </Switch>
      </Router>
    </Provider>
  );
}

export default App;
