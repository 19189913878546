import pharaohImage from './characterImages/pharaoh.png';
import scribeImage from './characterImages/scribe.png';
import embalmerImage from './characterImages/embalmer.png';
import soldierImage from './characterImages/soldier.png';
import eliteWomanImage from './characterImages/elite_woman.png';
import priestImage from './characterImages/priest.png';
import childImage from './characterImages/child.png';
import craftspersonImage from './characterImages/craftsperson.png';

export default [
  {
    imageUrl: pharaohImage,
    title: 'Pharaoh',
    description:
      'The ruler of Egypt\n' +
      'Represented the gods on Earth\n' +
      'Owned all the land in the country\n' +
      'Very wealthy',
  },
  {
    imageUrl: scribeImage,
    title: 'Scribe',
    description:
      'The only group in Egypt that could read and write\n' +
      'Wrote in hieroglyphs (picture writing)\n' +
      'Used papyrus paper and ink made from ground-up minerals',
  },
  {
    imageUrl: embalmerImage,
    title: 'Embalmer',
    description:
      'Special priests who dried and wrapped bodies ready for the Afterlife\n' +
      'Used special salts and oils\n' +
      'Placed the mummy’s organs into four ‘canopic jars’ with animal heads',
  },
  {
    imageUrl: soldierImage,
    title: 'Soldier',
    description:
      'Belonged to armies named after gods\n' +
      'Weapons included a curved sword called a khopesh\n' +
      'Often ‘conscripted’ (forced) into the army',
  },
  {
    imageUrl: eliteWomanImage,
    title: 'Elite woman',
    description:
      'Could afford expensive items made of gold and precious stones\n' +
      'Sometimes acted as a Priestess\n' +
      'Wealthy men and women all wore eye make-up ground from minerals',
  },
  {
    imageUrl: priestImage,
    title: 'Priest',
    description:
      'Made offerings and carried out rituals for the many gods\n' +
      'Represented the Pharaoh in temples\n' +
      'Only priests and priestesses could enter the temples',
  },
  {
    imageUrl: childImage,
    title: 'Child',
    description:
      'Most did not go to school\n' +
      'Had shaved heads with a ‘lock of youth’ on the side\n' +
      'Boys learnt to do their father’s job',
  },
  {
    imageUrl: craftspersonImage,
    title: 'Craftsperson',
    description:
      'Skilled boat-builders, painters, potters, jewellers and coffin-makers\n' +
      'Lived in their own part of town\n' +
      'Sometimes robbed the tombs they helped build!',
  },
];
