import React from 'react';

import papyrusImage from '../../assets/papyrus-horizontal.png';
import continueButtonImage from '../../assets/continue-game.png';

import classNames from './FailureModal.module.css';

const MODAL_HEADING1 = 'Looks like you made some mistakes!';
const MODAL_HEADING2 = 'Click the button below to try again';

export type FailureModalProps = {
  onFailureModalContinueGame: () => void;
};

export const FailureModal = ({
  onFailureModalContinueGame,
}: FailureModalProps) => (
  <div className={classNames.overlay}>
    <div
      className={classNames.modal}
      style={{ backgroundImage: `url(${papyrusImage})` }}
    >
      <h1>{MODAL_HEADING1}</h1>
      <h1>{MODAL_HEADING2}</h1>
      <button
        type="button"
        className={classNames.continueButton}
        onClick={onFailureModalContinueGame}
        style={{ backgroundImage: `url(${continueButtonImage})` }}
        data-testid="continue-game"
      />
    </div>
  </div>
);

export default FailureModal;
