import React from 'react';
import { NavLink, Link } from 'react-router-dom';

import { LANDING_PAGE_PATH } from '../LandingPage';

import classes from './Layout.module.css';

const LOGO = '/img/logos/BlackUoM.png';
const LOGO_ALT_TEXT = 'University of Manchester';

const MUSEUM_LOGO = '/img/logos/ManMuseum.jpg';
const MUSEUM_LOGO_ALT_TEXT = 'Manchester Museum';

const LANDING_PAGE_LINK_TEXT = 'Play';
const ABOUT_US_PAGE_LINK_TEXT = 'About';

export const ABOUT_US_EXTERNAL_LINK =
  'https://www.museum.manchester.ac.uk/learn/inflatablemuseum/';

const Layout: React.FC<React.ReactNode> = ({ children }) => {
  return (
    <div className={classes.Background}>
      <header className={classes.Header}>
        <div className={classes.NavigationWrapper}>
          <nav className={classes.Navigation}>
            <Link to={LANDING_PAGE_PATH}>
              <img alt={LOGO_ALT_TEXT} src={LOGO} className={classes.UoMLogo} />
            </Link>
            <ul>
              <li>
                <NavLink
                  exact
                  to={LANDING_PAGE_PATH}
                  activeClassName={classes.active}
                  data-test-id="Play"
                >
                  {LANDING_PAGE_LINK_TEXT}
                </NavLink>
              </li>
              <li>
                <a href={ABOUT_US_EXTERNAL_LINK}>{ABOUT_US_PAGE_LINK_TEXT}</a>
              </li>
            </ul>
          </nav>
          <img
            alt={MUSEUM_LOGO_ALT_TEXT}
            src={MUSEUM_LOGO}
            className={classes.MuseumLogo}
          />
        </div>
      </header>

      <div className={classes.Wrapper}>{children}</div>

      <footer className={classes.Footer}>
        <p>
          Icons made by{' '}
          <a href="https://www.flaticon.com/authors/freepik" title="Freepik">
            Freepik
          </a>{' '}
          from{' '}
          <a href="https://www.flaticon.com/" title="Flaticon">
            {' '}
            www.flaticon.com
          </a>
        </p>
        <p>© 2020 Manchester Museum</p>
      </footer>
    </div>
  );
};

export default Layout;
