import JsPDF from 'jspdf';
import path from 'path';
import './ArchitectsDaughter-Regular-normal';

export type PDFProps = {
  name: string;
  schoolName: string;
  tries: number;
  triesString: string;
};

const createPDF = ({ name, schoolName, tries, triesString }: PDFProps) => {
  const doc = new JsPDF({
    orientation: 'landscape',
  });

  doc.getFontList();
  doc.setFont('ArchitectsDaughter-Regular', 'normal');

  const papyrus = new Image();
  papyrus.src = path.resolve('/img/papyrus-horizontal-white.png');

  const uomMuseumLogo = new Image();
  uomMuseumLogo.src = path.resolve('/img/logos/ManMusTransp.png');

  const manMuseumLogo = new Image();
  manMuseumLogo.src = path.resolve('/img/logos/ManMusBlack.jpg');

  doc.addImage(papyrus, 'png', 0, 0, 297, 210);
  doc.addImage(uomMuseumLogo, 'png', 196, 40, 57.468, 28.363);
  doc.addImage(manMuseumLogo, 'png', 45, 40, 70.6, 13.3);

  const pageCenter = 297 / 2;
  doc.setFontSize(36);
  const congratulationsWidth = doc.getTextWidth('Congratulations') / 2;
  doc.text(pageCenter - congratulationsWidth, 120, 'Congratulations');

  doc.setFontSize(52);
  const nameWidth = doc.getTextWidth(name) / 2;
  doc.text(pageCenter - nameWidth, 90, name);

  doc.setFontSize(14);
  const schoolWidth = doc.getTextWidth(schoolName) / 2;
  doc.text(pageCenter - schoolWidth, 100, schoolName);

  doc.setFontSize(20);
  const messageWidth =
    doc.getTextWidth(
      `You figured out who's who in Ancient Egypt in only ${tries} ${triesString}!`
    ) / 2;
  doc.text(
    pageCenter - messageWidth,
    140,
    `You figured out who's who in Ancient Egypt in only ${tries} ${triesString}!`
  );

  return doc;

};

export default createPDF;
